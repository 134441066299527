import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import map from 'lodash/isEmpty';
import get from 'lodash/get';

import { useTranslation } from 'react-i18next';

import { withStyles, Container } from '@material-ui/core';
import DescriptionRow from '../DescriptionRow';

const styles = theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(4, 1),
  },
});

function ContentfulProductInformation({ classes, className, pageData }) {
  const { t } = useTranslation();
  const howItIsMade = get(pageData, 'howItIsMade');
  const taste = get(pageData, 'taste');
  const howToUse = get(pageData, 'howToUse');
  const additionalAttributes = get(pageData, 'additionalAttributes');

  return (
    <Container maxWidth="md" className={classes.container}>
      <div className={clsx(classes.root, className)}>
        <DescriptionRow title={t('ContentfulProduct.Attributes.howItIsMade')} value={howItIsMade} />
        <DescriptionRow title={t('ContentfulProduct.Attributes.taste')} value={taste} />
        <DescriptionRow title={t('ContentfulProduct.Attributes.howToUse')} value={howToUse} />
        {map(additionalAttributes, attribut => {
          const title = get(attribut, 'title');
          const content = get(attribut, 'content');
          const id = get(attribut, 'contentful_id');
          return <DescriptionRow title={title} value={content} key={id} />;
        })}
      </div>
    </Container>
  );
}

ContentfulProductInformation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ContentfulProductInformation.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ContentfulProductInformation);
