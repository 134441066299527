import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import KoskenkorvaContentfulProductInformation from './koskenkorva';

function ContentfulProductInformation(props) {
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaContentfulProductInformation {...props} />;
  }

  return null;
}

export default ContentfulProductInformation;
