/* eslint-disable react/no-danger */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

const constructOfferItem = (pageData, price) => {
  let currency = 'EUR';
  let sellerName = 'Alko Oy';
  if (isEqual(siteId, siteIds.Folkofolk)) {
    currency = 'SEK';
    sellerName = 'Systembolaget';
  }
  return {
    '@type': 'Offer',
    priceCurrency: currency,
    price,
    url: get(pageData, 'attributes.monopoly_url.value'),
    seller: {
      '@type': 'Organization',
      name: sellerName,
    },
    itemCondition: 'https://schema.org/NewCondition',
    availability: 'https://schema.org/InStock',
  };
};

const getPrices = pageData => {
  const prices = [];
  const regularPrice = get(pageData, 'price');
  const salePrice = get(pageData, 'attributes.monopoly_reduced_price.value');
  prices.push(constructOfferItem(pageData, regularPrice));
  if (salePrice) {
    prices.push(constructOfferItem(pageData, salePrice));
  }
  return prices;
};

const constructReviewItem = criticReview => {
  return {
    '@type': 'CriticReview',
    author: {
      '@type': 'Organization',
      name: get(criticReview, 'reviewer'),
    },
    reviewBody: get(criticReview, 'review'),
  };
};

const getCriticReviews = pageData => {
  const criticReviews = get(pageData, 'attributes.review_data.value');
  const criticReviewSchema = [];

  if (criticReviews) {
    JSON.parse(criticReviews).map(criticReview => criticReviewSchema.push(constructReviewItem(criticReview)));
  }

  return criticReviewSchema;
};

const getReviewCount = pageData => {
  const criticReviews = get(pageData, 'attributes.review_data.value');

  if (criticReviews) {
    return JSON.parse(criticReviews).length;
  }
  return 0;
};

const getAggregateRating = pageData => {
  const reviewCount = getReviewCount(pageData);

  if (reviewCount) {
    return {
      '@type': 'AggregateRating',
      reviewCount: getReviewCount(pageData),
      ratingValue: '0',
      bestRating: '0',
      worstRating: '0',
    };
  }
  return null;
};

function ProductStructuredData({ pageData }) {
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
          description
          siteUrl
          defaultLanguage
        }
      }
    }
  `);

  const siteUrl = get(query, 'site.siteMetadata.siteUrl');

  const structuredData = useMemo(() => {
    return JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'Product',
      brand: {
        '@type': 'Brand',
        name: get(pageData, 'attributes.brand_ecomm.value'),
      },
      countryOfOrigin: get(pageData, 'attributes.country_of_origin.value'),
      description: get(pageData, 'attributes.short_description.value'),
      image: get(pageData, 'heroImage.cloudinaryImage[0].secure_url'),
      name: get(pageData, 'name'),
      offers: getPrices(pageData),
      review: getCriticReviews(pageData),
      size: get(pageData, 'attributes.volume.value') + get(pageData, 'attributes.base_unit.value'),
      sku: get(pageData, 'sku'),
      url: `${siteUrl}${get(pageData, 'fullPath')}`,
      aggregateRating: getAggregateRating(pageData),
    });
  }, [pageData, siteUrl]);

  return (
    <Helmet>
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
}

ProductStructuredData.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ProductStructuredData.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default ProductStructuredData;
