/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { createCloudinaryImageUrl } from '../../../../utils/cloudinaryUtils';

const constructOfferItem = (pageData, price) => {
  const currency = 'EUR';
  const availability = get(pageData, 'availabilty');
  return {
    '@type': 'Offer',
    priceCurrency: currency,
    price,
    url: get(pageData, 'whereToBuy'),
    itemCondition: 'https://schema.org/NewCondition',
    ...(!isEmpty(availability) ? { availability: 'https://schema.org/InStock' } : {}),
  };
};

const getPrices = pageData => {
  const prices = [];
  const normalPrice = get(pageData, 'normalPrice');
  const discountPrice = get(pageData, 'discountPrice');
  prices.push(constructOfferItem(pageData, normalPrice));
  if (discountPrice) {
    prices.push(constructOfferItem(pageData, discountPrice));
  }
  return prices;
};

const getImage = pageData => {
  const ogImageTransformation = 'OgProductImage-LG-MD';
  const image = get(pageData, 'images.0.cloudinaryImage.0');

  if (!isEmpty(image)) {
    const publicId = get(image, 'public_id');
    return createCloudinaryImageUrl(ogImageTransformation, publicId);
  }
  return null;
};

function ProductStructuredData({ pageData }) {
  const { t } = useTranslation();
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
          description
          siteUrl
          defaultLanguage
        }
      }
    }
  `);

  const siteUrl = get(query, 'site.siteMetadata.siteUrl');

  const structuredData = useMemo(() => {
    const countryOfOrigin = get(pageData, 'countryOfOrigin');
    return JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'Product',
      brand: {
        '@type': 'Brand',
        name: get(pageData, 'brand.title'),
      },
      category: get(pageData, 'categories.0.title'),
      ...(!isEmpty(countryOfOrigin)
        ? { countryOfOrigin: t(`ContentfulProduct.country_of_origin.${countryOfOrigin}`) }
        : {}),
      description: get(pageData, 'shortDescription'),
      image: getImage(pageData),
      name: get(pageData, 'name'),
      offers: getPrices(pageData),
      url: `${siteUrl}${get(pageData, 'fullPath')}`,
    });
  }, [pageData, siteUrl]);

  return (
    <Helmet>
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
}

ProductStructuredData.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ProductStructuredData.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default ProductStructuredData;
