import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import KoskenkorvaThemeProductPageContent from './koskenkorva';

function ThemeProductPageContent(props) {
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaThemeProductPageContent {...props} />;
  }

  return null;
}

export default ThemeProductPageContent;
