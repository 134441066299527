import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import isNaN from 'lodash/isNaN';
import capitalize from 'lodash/capitalize';

import { settings, siteId } from '../../../../../sites';

import { createCloudinaryImageUrl } from '../../../../../utils/cloudinaryUtils';

const createMeta = (page, seoTitle, seoCanonical, query) => {
  const tags = [];
  const currentLocale = get(page, 'languageCode', settings.localization.defaultLanguageCode);
  const siteMetadataDescriptions = JSON.parse(get(query, 'site.siteMetadata.description'));
  const currentSiteMetadataDescription = get(siteMetadataDescriptions, currentLocale);

  tags.push({ property: 'og:site_name', content: capitalize(siteId) });

  if (!isEmpty(seoTitle)) {
    tags.push({ property: 'og:title', content: `${seoTitle}` });
    tags.push({ name: 'twitter:title', content: `${seoTitle}` });
  }

  const metaDescription = get(page, 'shortDescription');

  if (!isEmpty(metaDescription)) {
    tags.push({ name: 'description', content: metaDescription });
  }
  if (isEmpty(metaDescription)) {
    tags.push({ name: 'description', currentSiteMetadataDescription });
  }

  if (!isEmpty(metaDescription)) {
    tags.push({ property: 'og:description', content: metaDescription });
    tags.push({ name: 'twitter:description', content: metaDescription });
  }

  if (!isEmpty(seoCanonical)) {
    tags.push({ property: 'og:url', content: seoCanonical });
  }

  const image = get(page, 'images.0.cloudinaryImage.0');
  const ogImageTransformation = 'OgProductImage-LG-MD';

  if (!isEmpty(image)) {
    const publicId = get(image, 'public_id');
    if (!isEmpty(publicId)) {
      tags.push({ property: 'og:image', content: createCloudinaryImageUrl(ogImageTransformation, publicId) });
      tags.push({ property: 'og:image:url', content: createCloudinaryImageUrl(ogImageTransformation, publicId) });
      tags.push({
        property: 'og:image:secure_url',
        content: createCloudinaryImageUrl(ogImageTransformation, publicId),
      });
      tags.push({ property: 'og:image:width', content: '1200px' });
      tags.push({ property: 'og:image:height', content: '627px' });
    }
  }

  const type = 'product';
  tags.push({ property: 'og:type', content: type });

  let price = round(get(page, 'discountPrice'), 2);
  if (isEmpty(price)) {
    price = round(get(page, 'normalPrice'), 2);
  }
  if (!isNaN(price)) {
    tags.push({ property: 'og:product:price:amount', content: price });
    const currency = 'EUR';
    tags.push({ property: 'og:product:price:currency', content: currency });
  }

  return tags;
};

export default createMeta;
