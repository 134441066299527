/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Header from '../../../components/layout/Header';
import Footer from '../../../components/layout/Footer';
import Title from '../../../components/page/Title';
import ContentfulProductDescription from '../../../components/page/ContentfulProduct/ContentfulProductDescription';
import ShortDescription from '../../../components/page/ShortDescription';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import ContentfulProductInformation from '../../../components/page/ContentfulProduct/ContentfulProductInformation';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';
import ProductSeoFields from '../../../components/shared/Products/ProductSeoFields';
import ProductStructuredData from '../../../components/page/ProductStructuredData';
import ThemeProductPageContent from '../../../components/page/ContentfulProduct/ThemeProductPageContent';
import PageBrowseTracker from '../../../components/page/PageBrowseTracker';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';

const styles = theme => ({
  root: {
    ...theme.palette.pageTemplates.otherPageTemplates,
  },
});

function ContentfulProductPageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulProduct');
  const analytics = get(pageData, 'mainTheme.analytics');

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <ProductSeoFields pageData={pageData} />
      <ProductStructuredData pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main>
        <SiteNotification pageData={pageData} />
        <Title pageData={pageData} />
        <ShortDescription pageData={pageData} />
        <ContentfulProductDescription pageData={pageData} />
        <ContentfulProductInformation pageData={pageData} />
        <ThemeProductPageContent pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

ContentfulProductPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

ContentfulProductPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export const pageQuery = graphql`
  query ContentfulProductPageTemplate($pageId: String!) {
    contentfulProduct(id: { eq: $pageId }) {
      id
      adminTitle
      contentful_id
      name
      shortDescription
      fullPath
      languageCode
      mainTheme {
        ...themeFragment
      }
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      brand {
        ...BrandPageFragment
      }

      categories {
        ...CategoryFragment
      }
      images {
        ...HeroImageFragment
        ...ContentImageModuleFragment
      }

      awards {
        raw
        references {
          ... on Node {
            ...ContentImageModuleFragment
            ...ContentReferenceFragment
          }
        }
      }
      description {
        raw
        references {
          ... on Node {
            ...ContentButtonLinkModuleFragment
            ...ContentLinkBlockModuleFragment
            ...ContentImageModuleFragment
          }
        }
      }
      howItIsMade {
        raw
        references {
          ... on Node {
            ...ContentReferenceFragment
          }
        }
      }
      howToUse {
        raw
        references {
          ... on Node {
            ...ContentReferenceFragment
          }
        }
      }
      taste {
        raw
        references {
          ... on Node {
            ...ContentReferenceFragment
          }
        }
      }
      alcoholVolume
      availability
      countryOfOrigin
      discountPrice
      node_locale
      normalPrice
      packagingType
      whereToBuy
      aroma {
        aroma
      }
      color {
        color
      }
      size
      producer
      additionalAttributes {
        title
        id
        contentful_id
        content {
          raw
          references {
            ... on Node {
              ...ContentReferenceFragment
            }
          }
        }
      }
    }
  }
`;

export default withSecondaryTheme(withStyles(styles)(ContentfulProductPageTemplate));
