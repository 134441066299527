import { get, isEmpty, round, isNaN, isEqual } from 'lodash';
import { siteIds } from '../../../../../constants';
import { siteId, settings } from '../../../../../sites';

import { createCloudinaryImageUrl } from '../../../../../utils/cloudinaryUtils';

const createMeta = (page, seoTitle, seoCanonical, parentPage) => {
  const tags = [];

  const currentLocale = get(page, 'languageCode', settings.localization.defaultLanguageCode);
  const siteMetadataDescriptions = JSON.parse(get(parentPage, 'site.siteMetadata.description'));
  const currentSiteMetadataDescription = get(siteMetadataDescriptions, currentLocale);

  let site = 'Viinimaa';
  if (isEqual(siteId, siteIds.Folkofolk)) {
    site = 'Folkofolk';
  }

  tags.push({ property: 'og:site_name', content: site });

  if (!isEmpty(seoTitle)) {
    tags.push({ property: 'og:title', content: `${seoTitle}` });
    tags.push({ name: 'twitter:title', content: `${seoTitle}` });
  }

  let metaDescription = get(page, 'attributes.short_description.value');

  if (isEqual(siteId, siteIds.Folkofolk)) {
    metaDescription = get(page, 'attributes.description.value');
    if (!isEmpty(metaDescription)) {
      metaDescription = get(page, 'attributes.short_description.value');
    }
  }
  if (!isEmpty(metaDescription)) {
    tags.push({ name: 'description', content: metaDescription });
  }
  if (isEmpty(metaDescription)) {
    tags.push({ name: 'description', currentSiteMetadataDescription });
  }

  if (!isEmpty(metaDescription)) {
    tags.push({ property: 'og:description', content: metaDescription });
    tags.push({ name: 'twitter:description', content: metaDescription });
  }

  if (!isEmpty(seoCanonical)) {
    tags.push({ property: 'og:url', content: seoCanonical });
  }

  const image = get(page, 'heroImage.cloudinaryImage.0');
  const ogImageTransformation = 'OgProductImage-LG-MD';

  if (!isEmpty(image)) {
    const publicId = get(image, 'public_id');
    if (!isEmpty(publicId)) {
      tags.push({ property: 'og:image', content: createCloudinaryImageUrl(ogImageTransformation, publicId) });
      tags.push({ property: 'og:image:url', content: createCloudinaryImageUrl(ogImageTransformation, publicId) });
      tags.push({
        property: 'og:image:secure_url',
        content: createCloudinaryImageUrl(ogImageTransformation, publicId),
      });
      tags.push({ property: 'og:image:width', content: '1200px' });
      tags.push({ property: 'og:image:height', content: '627px' });
    }
  }

  const type = 'product';
  tags.push({ property: 'og:type', content: type });

  const price = round(get(page, 'price'), 2);
  if (!isNaN(price)) {
    tags.push({ property: 'og:product:price:amount', content: price });
  }

  let currency = 'EUR';
  if (isEqual(siteId, siteIds.Folkofolk)) {
    currency = 'SEK';
  }
  tags.push({ property: 'og:product:price:currency', content: currency });

  return tags;
};

export default createMeta;
