import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import { withStyles } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { useTranslation } from 'react-i18next';

import Button from '../../../../shared/Button';
import { clickAmazonAffiliateLink } from '../../../../../utils/analyticUtils';

const styles = () => ({
  root: {
    width: '100%',
  },
});

function WhereToBuyBotton({ classes, className, to, ...otherProps }) {
  const { t } = useTranslation();
  if (isEmpty(to)) {
    return null;
  }
  const handleClick = () => {
    if (includes(to, 'https://www.amazon.de')) {
      clickAmazonAffiliateLink();
    }
  };
  return (
    <Button
      className={clsx(classes.root, className)}
      to={to}
      onClick={handleClick}
      {...otherProps}
      endIcon={<ArrowForward className={classes.icon} />}>
      {t('ContentfulProduct.WhereToBuyButton.label')}
    </Button>
  );
}

WhereToBuyBotton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  to: PropTypes.string,
};

WhereToBuyBotton.defaultProps = {
  classes: {},
  className: null,
  to: null,
};

export default withStyles(styles)(WhereToBuyBotton);
