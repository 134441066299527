import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Container, withStyles, Divider } from '@material-ui/core';
import ContentfulProductImage from '../../ContentfulProductImage';
import { productDefaultImage } from '../../../../../constants';
import RichText from '../../../../shared/RichText';
import WhereToBuyBotton from '../../WhereToBuyBotton';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
  },
  container: {
    padding: '0px 24px',
  },
  imageSide: {
    display: 'flex',
    justifyContent: 'center',
    flexBasis: '100%',
    maxWidth: '100%',
    paddingBottom: '24px',
  },
  informations: {
    display: 'flex',
    gap: theme.spacing(5),
    justifyContent: 'center',
    flexDirection: 'column',
    flexBasis: '1000%',
    maxWidth: '100%',
    padding: '24px 24px 24px 24px',
  },
  divider: {
    ...theme.hr,
  },
});

function ContentfulProductDescription({ classes, className, pageData }) {
  const description = get(pageData, 'description');
  const whereToBuy = get(pageData, 'whereToBuy');

  return (
    <Container maxWidth="md" className={classes.container}>
      <div className={clsx(classes.root, className)}>
        <div className={classes.imageSide}>
          <ContentfulProductImage pageData={pageData} defaultImage={productDefaultImage.Viinimaa} />
        </div>
        {isEmpty(whereToBuy) && <Divider className={classes.divider} />}

        <div className={classes.informations}>
          <WhereToBuyBotton to={whereToBuy} />
          <RichText textData={description} />
        </div>
      </div>
    </Container>
  );
}

ContentfulProductDescription.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ContentfulProductDescription.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ContentfulProductDescription);
