import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import KoskenkorvaContentfulProductImage from './koskenkorva';

function ContentfulProductImage(props) {
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <KoskenkorvaContentfulProductImage {...props} />;
  }

  return null;
}

export default ContentfulProductImage;
