import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import ThumbnailImage from '../../../../shared/ThumbnailImage';
import { breakPoints } from '../../../../../constants';

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '400px',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '600px',
    },
  },
  imageWrapper: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  image: {},
});

function ContentfulProductImage({ classes, className, pageData, defaultImage }) {
  let heroImage = get(pageData, 'images.0');
  /*
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();
  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.sm) {
        setIsMobile(false);
      }
      if (width < breakPoints.sm) {
        setIsMobile(true);
      }
    }
    return () => {
      return null;
    };
  }, [width]);
*/
  if (isEmpty(get(heroImage, 'cloudinaryImage.0.public_id'))) {
    heroImage = {
      cloudinaryImage: [{ public_id: defaultImage }],
      imageAltText: get(pageData, 'title'),
      imageTitleText: get(pageData, 'title'),
    };
  }
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.imageWrapper}>
        <ThumbnailImage
          className={classes.image}
          data={heroImage}
          transformation="ProductThumbnailImage-IN-SM"
          height="100%"
          width="auto"
          circle={false}
        />
      </div>
    </div>
  );
}

ContentfulProductImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultImage: PropTypes.string,
};

ContentfulProductImage.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  defaultImage: null,
};

export default withStyles(styles)(ContentfulProductImage);
