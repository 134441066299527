/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import createMeta from './createMeta';

function ProductSeoFields({ pageData }) {
  const { pathname } = useLocation();

  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          description
          siteUrl
        }
      }
    }
  `);

  const siteUrl = get(query, 'site.siteMetadata.siteUrl');
  const seoCanonical = `${siteUrl}${pathname}`;
  const pageTitle = get(pageData, 'name', 'Product');
  const titleTemplate = `%s`;
  const meta = createMeta(pageData, pageTitle, seoCanonical, query);

  return (
    <Helmet title={`${pageTitle}`} titleTemplate={titleTemplate} defaultTitle={pageTitle} meta={meta} defer={false}>
      <link rel="canonical" href={seoCanonical} />
    </Helmet>
  );
}

ProductSeoFields.propTypes = {
  pageData: PropTypes.object,
};

ProductSeoFields.defaultProps = {
  pageData: null,
};

export default ProductSeoFields;
