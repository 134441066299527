import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { withStyles } from '@material-ui/core';

import ContentRows from '../../../ContentRows';

const styles = () => ({
  root: {},
});

function ThemeProductPageContent({ classes, className, pageData, ...otherProps }) {
  const data = get(pageData, 'mainTheme.productPageContent');

  if (isEmpty(data)) {
    return null;
  }
  return <ContentRows pageData={{ contentRows: data }} className={clsx(classes.root, className)} {...otherProps} />;
}

ThemeProductPageContent.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ThemeProductPageContent.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ThemeProductPageContent);
