import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import RichText from '../../../shared/RichText';

const styles = () => ({
  root: {
    display: 'block',
    marginBottom: '30px',
    '@media print': {
      pageBreakInside: 'avoid',
    },
  },
  title: {
    flexBasis: '40%',
    marginBottom: '16px',
  },
  value: {},
});

function DescriptionRow({ classes, title, value }) {
  if (isNull(value) || isEmpty(value)) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      {!isObject(value) && (
        <Typography variant="body1" className={classes.value}>
          {value}
        </Typography>
      )}
      {isObject(value) && <RichText textData={value} />}
    </div>
  );
}

DescriptionRow.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
};

DescriptionRow.defaultProps = {
  classes: {},
  className: null,
  title: '',
  value: null,
};

export default withStyles(styles)(DescriptionRow);
